/** @jsx jsx */
import { jsx, Themed, Button } from "theme-ui"
import { darken } from "@theme-ui/color"
import { Link } from "gatsby"
import { RoughNotation } from "react-rough-notation"
import { useHasMounted } from "gatsby-theme-catalyst-core"
import { Fragment } from "react"

const Hero = () => {
  /*
  const data = useStaticQuery(graphql`
    {
      heroImage: file(relativePath: { eq: "hero-image.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const heroImage = data.heroImage.childImageSharp.gatsbyImageData
  */
  const hasMounted = useHasMounted()
  return (
    <div
      sx={{
        width: "100vw",
        position: "relative",
        left: "calc(-50vw + 50%)",
        mb: 4,
      }}
    >
      <div
        sx={{
          maxWidth: "maxPageWidth",
          mx: "auto",
        }}
      >
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["1fr", null, "1fr 1fr", null, null],
            alignItems: "center",
            gridGap: [0, null, 5, null, null],
            mx: [3, null, 5, null, null],
          }}
        >
          <img
            src="\images\hero-image.svg"
            alt="Hero"
            sx={{
              gridColumn: ["1 / -1", null, "2 / 3", null, null],
              gridRow: "1 / 2",
              maxHeight: [null, "200px", "300px", "400px", "500px"],
              maxWidth: [null, "200px", "300px", "400px", "500px"],
            }}
          />
          <div
            sx={{
              gridColumn: ["1 / -1", null, "1 / 2", null, null],
              gridRow: ["2 / 3", null, "1 / 2", null, null],
            }}
          >
            <Themed.p sx={{ fontSize: [3, null, null, null, 4] }}>
              {
                "Hello World! My name is Jason Christensen. I am a software craftsman with over 20 years of experience and have a passion for "
              }
              {hasMounted ? (
                <Fragment>
                  <RoughNotation type="underline" show={true} strokeWidth={2}>
                    learning
                  </RoughNotation>
                  <span> and </span>
                  <RoughNotation type="underline" show={true} strokeWidth={2}>
                    technologies.
                  </RoughNotation>
                </Fragment>
              ) : (
                "connection and belonging."
              )}
            </Themed.p>
            <Button
              as={Link}
              to="/contact"
              sx={{
                bg: "#0004f7",
                fontSize: [2, null, null, null, 3],
                transition: "all 0.3s ease-in-out",
                ":hover": {
                  bg: darken("#0004f7", 0.08),
                },
              }}
            >
              Contact me
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
