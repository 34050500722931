import { useEffect } from "react"
/** @jsx jsx */
import { jsx, Label, Input, Textarea, Button } from "theme-ui"
import kwesforms from "kwesforms"

const ContactForm = () => {
  useEffect(() => {
    // Runs after the first render() lifecycle
    kwesforms.init()
  }, [])

  return (
    <div
      sx={{
        maxWidth: "maxPageWidth",
        mx: "auto",
      }}
    >
      <div
        sx={{
          display: "grid",
          alignItems: "center",
          mx: [3, null, 5, null, null],
        }}
      >
        <form
          className="kwes-form"
          action="https://kwesforms.com/api/foreign/forms/6A5QiRBNhp65MyGKKfWO"
        >
          <Label htmlFor="name">Name</Label>
          <Input
            type="text"
            name="name"
            rules="required|max:255"
            mb={3}
            sx={{ WebkitTextFillColor: "var(--theme-ui-colors-text)" }}
          />
          <Label htmlFor="email">Email</Label>
          <Input
            type="email"
            name="email"
            rules="required|email"
            mb={3}
            sx={{ WebkitTextFillColor: "var(--theme-ui-colors-text)" }}
          />
          <Label htmlFor="message">Message</Label>
          <Textarea
            name="message"
            rows="10"
            mb={3}
            sx={{ WebkitTextFillColor: "var(--theme-ui-colors-text)" }}
          />
          <Button type="submit" mb={3}>
            Submit
          </Button>
        </form>
      </div>
    </div>
  )
}

export default ContactForm
